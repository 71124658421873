<template>
    <div class="content-top">
        <!-- Import the symbols offset canvas -->
         <div class="mask-layer" v-if="maskLayer"></div>
        <div class="left">
            <div class="trading-chart">
                <div class="ticker bg-title text-content">
                    <!--
                    FOR VERSION 4.4
                    -->
                    <template>
                        <div stop="1" class="symbol-name">
                            <div class="d-flex flex-row">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ $t('general.spot_goods') }}
                                    </button>
                                    <ul class="dropdown-menu">
                                        <router-link to="/futures" class="dropdown-item">{{ $t('general.futures') }}</router-link>
                                        <router-link to="/exchange" class="dropdown-item">{{ $t('general.spot_goods') }}</router-link>
                                        <router-link v-if="sysconfig.bOptionEnabled" to="/boption" class="dropdown-item">{{ $t('general.boption') }}</router-link>
                                    </ul>
                                </div>
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ symbol.metadata.name }}
                                    </button>
                                    <div class="dropdown-menu">
                                        <router-link v-for="sym in symbols" :key="sym.id" :to="'/exchange/' + sym.symbol.toLowerCase()" class="dropdown-item">
                                            {{ sym.name }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div growing-ignore="true" v-bind:class="'price-container ' + (symbol.up ? 'color-up' : 'color-down')">
                            <div>{{ symbol.price }}</div>
                            <div class="price-change">{{ symbol.price_change }}</div>
                        </div>
                        <dl growing-ignore="true" class="change">
                            <dt class="text-label">{{ $t('exchange.24h_change') }}</dt>
                            <dd v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price_change_pct }}</dd>
                        </dl>
                        <dl growing-ignore="true" class="low d-none d-sm-block">
                            <dt class="text-label">{{ $t('exchange.24h_low') }}</dt>
                            <dd>{{ symbol.day_low }}</dd>
                        </dl>
                        <dl growing-ignore="true" class="high d-none d-sm-block">
                            <dt class="text-label">{{ $t('exchange.24h_high') }}</dt>
                            <dd>{{ symbol.day_high }}</dd>
                        </dl>
                        <dl growing-ignore="true" class="amount d-none d-sm-block">
                            <dt class="text-label">{{ $t('exchange.24h_vol') }}</dt>
                            <dd>{{ symbol.day_vol }}</dd>
                        </dl>
                        <div class="goto-chart" @click="showChart"><i class="fa fa-chart-bar"></i></div>
                    </template>
                </div>
                <div class="trading-chart-container bg-content">
                    <div class="chart-container">
                        <div id="tv_chart_container" class="chart">
                            <!-- Display the loading indicator by default -->
                            <loading-indicator />
                        </div>
                    </div>
                </div>
            </div>
            <create-order-panel ref="createOrderPanel" :symbol="symbol" @order-created="onOrderCreated" />
            <div class="bottom-trade">
                <div class="row">
                    <div class="col">
                        <button class="btn-submit bg-buy" @click="showTrade('buy')">{{ $t('exchange.orders_buy') }}</button>
                    </div>
                    <div class="col">
                        <button class="btn-submit bg-sell" @click="showTrade('sell')">{{ $t('exchange.orders_sell') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="order-books">
                <div class="mod-body bg-content">
                    <dl>
                        <dt class="bg-title text-label">
                            <span class="price">{{ $t('exchange.depth_price') }} ({{ symbol.metadata.quote_symbol }})</span>
                            <span class="amount">{{ $t('exchange.depth_volume') }} ({{ symbol.metadata.base_symbol }})</span>
                            <span class="total">{{ $t('exchange.depth_total_volume') }} ({{ symbol.metadata.base_symbol }})</span>
                        </dt>
                        <dd class="now-pric text-content">
                            <div class="now-price">
                                <dl>
                                    <dt v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price }}</dt>
                                </dl>
                            </div>
                            <div class="asks">
                                <p @click="onPriceSelected(order.price)" v-for="order in order_books.asks" v-bind:key="order.key">
                                    <span class="price color-down">{{ order.price }}</span>
                                    <span class="amount">{{ order.volume }}</span>
                                    <span class="total">{{ order.total }}</span>
                                </p>
                            </div>
                            <div class="bids">
                                <p @click="onPriceSelected(order.price)" v-for="order in order_books.bids" v-bind:key="order.key">
                                    <span class="price color-up">{{ order.price }}</span>
                                    <span class="amount">{{ order.volume }}</span>
                                    <span class="total">{{ order.total }}</span>
                                </p>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getSymbolManagerAsync } from 'utilities/helper';
import * as chart from 'utilities/QuoteDataFeed.js';
import { Dropdown } from 'bootstrap';

import CreateOrderPanel from './Exchange_CreateOrderPanel.vue';
import SymbolsSideNav from '../../Components/_SymbolsSideNav.vue';

// The date time when the last quote is received.
let g_lastQuoteTime = new Date();

export default {
    components: { CreateOrderPanel, SymbolsSideNav },

    props: ['symbol'],

    data() {
        return {
            order_books: {
                asks: [],
                bids: []
            },

            symbols: [],

            chartVersion: 0,

            // Indicates whether the component has been destoyed or not.
            destroyed: false,
            maskLayer:false
        };
    },

    created() {
        this.destroyed = false;
        this.getExchangeSymbols();
    },

    beforeDestroy() {
        console.log(`## Destroy the exchange page.`);
        this.destroyed = true;

        // Stop socket io connections
        $.closeSocketIo();
        chart.destroyChart();
    },

    mounted() {
        this.initChartAsync();

        const dropdownElementList = document.querySelectorAll('.dropdown .dropdown-toggle')
        const dropdownList = [...dropdownElementList].map(elm => {
            $(elm).click(() => {
                const d = Dropdown.getOrCreateInstance(elm);
                d.show();
                this.maskLayer=true;
            });
        });
    },

    methods: {
        showSideNav: function () {
            this.$refs.symbolsSideNav.show()
        },

        initChartAsync: async function () {
            const sym = this.symbol;
            const self = this;

            // Clear any existing klines
            chart.setInitialKlines(null);

            // Read initial klines
            const end_ts = Math.floor(new Date().setSeconds(0, 0) / 1000);
            const from_ts = end_ts - 1500 * 60; // request klines for past 1500 minutes.
            const request_url = `/api/v1/quotation/klines?id=${encodeURIComponent(sym.metadata.id)}&type=1&from=${from_ts}&to=${end_ts}&limit=1500`;

            try {
                const resp = await $.callPostApi(self, request_url);
                if (resp) {
                    chart.setInitialKlines(resp);
                }
            } catch (err) {
                console.log(`Failed to read initial klines: ${err}`);
            }

            // Initialize the tradingview chart
            chart.initializeChart(sym, {
                region: this.sysconfig.region,
                locale: this.$i18n.locale,
                uiVersion: this.$version,
                defaultInterval: this.sysconfig.defaultInterval,
                tzOffset: this.sysconfig.tzOffset
            });

            // Start a socket io connection
            const pricePrecison = sym.metadata.pricePrecision;
            const amountPrecison = sym.metadata.volumePrecision;
            function parse_depth(src, key_prefix) {
                let total_vol = 0;
                const arr = [];
                for (let i = 0; i < src.length; i++) {
                    let item = src[i];
                    total_vol += item[1];
                    arr[i] = {
                        key: key_prefix + i,
                        price: item[0].toFixed(pricePrecison),
                        volume: item[1].toFixed(amountPrecison),
                        total: total_vol.toFixed(amountPrecison)
                    };
                }

                return arr;
            }

            g_lastQuoteTime = new Date();
            const chartVersion = ++this.chartVersion;

            $.initSocketIo(
                '/f' + this.symbol.metadata.id,
                (quote) => {
                    if (self.destroyed === false) {
                        if (self.chartVersion !== chartVersion) {
                            return;
                        }

                        // Is there a big gap for the latest quote?
                        const now = new Date();
                        const gap = now - g_lastQuoteTime;
                        if (gap > 300 * 1000) {
                            setTimeout(() => {
                                console.log('#### rebuild chart ######');
                                $.closeSocketIo();
                                self.initChartAsync();
                            }, 0);
                        } else {
                            g_lastQuoteTime = now;

                            // Update quote.
                            sym.updateRtqs(quote);
                            chart.updateRtqsToChart(quote);
                        }
                    }
                },
                (depth) => {
                    // Parse depth data
                    self.order_books.bids = parse_depth(depth.buy, 'bid');
                    self.order_books.asks = parse_depth(depth.sell, 'ask');
                }
            );
        },

        getExchangeSymbols: function () {
            const self = this;
            getSymbolManagerAsync().then((mgr) => {
                self.symbols = mgr.getSymbols(3); // 3: exchange symbol
            });
        },

        showTrade: function (type) {
            this.$refs.createOrderPanel.setVisibleType(type);
            $('.trade-layout').addClass('skip-chart');
        },

        showChart: function () {
            $('.trade-layout').removeClass('skip-chart');
        },

        onPriceSelected: function (price) {
            this.$refs.createOrderPanel.setOrderPrice(price);
        },

        onOrderCreated: function () {
            this.$emit('order-created');
        }
    }
};
</script>
<style scoped>
.ticker .dropdown-menu {
    background: #fbfbfb;
}
.ticker .dropdown-item:hover{
    background: #5ba0ff;
    font-weight: 600;
}
.dropdown{
   z-index: 9;
}
</style>