<template>
    <section class="page orders-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('home.header_boption_orders') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <ul class="page-top-nav">
                        <li class="active">
                            <router-link class="nav-link active" to="/boption/openorders">{{ $t('orders.open_orders') }}</router-link>
                        </li>
                        <li>
                            <router-link class="nav-link" to="/boption/orders">{{ $t('orders.all_orders') }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="!openOrders" class="row">
                <div class="col">
                    <loading-indicator />
                </div>
            </div>
            <template v-else>
                <div v-if="openOrders.length === 0" class="row">
                    <div class="col">
                        <div class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="order-item" v-bind:key="order.orderId" v-for="order in openOrders">
                            <div class="history-order">
                                <div class="row">
                                    <div class="col">
                                        <label>{{ $t('orders.label_symbol') }}</label> {{ order.name }} {{ order.duration }}S
                                        <span class="badge bg-danger">{{ $t('orders.status_open') }}</span>
                                    </div>
                                    <div class="col">
                                        <label>{{ $t('orders.label_direction') }}</label>
                                        <span v-if="order.buyUp" class="color-up">{{ $t('orders.label_direction_up') }} <i class="fa fa-arrow-up"></i></span>
                                        <span v-else class="color-down">{{ $t('orders.label_direction_down') }} <i class="fa fa-arrow-down"></i></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <label>{{ $t('orders.label_amount') }}</label> {{ order.amount }}{{ currencySymbol }}
                                    </div>
                                    <div class="col">
                                        <label>{{ $t('orders.label_currency') }}</label>
                                        {{ order.currency === 'USDT' ? $t('orders.label_currency_cash') : $t('orders.label_currency_coupon') }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <label>{{ $t('orders.label_create_price') }}</label> {{ order.createPrice }}
                                    </div>
                                    <div class="col">
                                        <label>{{ $t('orders.label_current_price') }}</label> <b>{{ order.closePrice }}</b>
                                    </div>
                                    <!-- <div class="col">
                                        <label>{{ $t('orders.label_fee') }}</label> {{ order.fee }}
                                    </div> -->
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label>{{ $t('orders.label_estimated_profit') }}</label> {{ order.profit }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label>{{ $t('orders.label_create_time') }}</label> {{ order.timeCreated }}
                                    </div>
                                    <div class="col">
                                        <label>{{ $t('orders.label_countdown') }}</label> {{ order.remainingSeconds }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
const OpenOrder = function (order) {
    this.sid = order.symbolId;
    this.name = order.symbolName;
    this.duration = order.duration;
    this.buyUp = order.buyUp;
    this.createPrice = order.createPrice.toFixed(order.pricePrecision);
    this.currency = order.currency;
    this.amount = order.amount;
    this.fee = order.fee.toFixed(2);
    this.timeCreated = new Date(order.timeCreated).formatDateTime(true);

    this.remainingSeconds = order.remainingSeconds;
    this.completed = false;
    this.closePrice = '--';
    this.profit = '--';

    /**
     * Update estimated profit of the order based on the specified latest price.
     */
    this.updateProfit = function (price) {
        let profit = 0;
        if (order.createPrice == price) {
            profit = 0;
        } else {
            profit = order.amount * order.profitRate;
            if ((price > order.createPrice) ^ order.buyUp) {
                profit = -profit;
            }
        }

        this.closePrice = price.toFixed(order.pricePrecision);
        this.profit = profit.toFixed(2);
    };

    /**
     * Update progress of the order.
     */
    this.updateProgress = function (diffMS) {
        if (!this.completed) {
            let remainingMS = order.remainingSeconds * 1000 - diffMS;
            this.remainingSeconds = Math.max(0, remainingMS / 1000).toFixed(1) + 'S';

            if (remainingMS <= 0) {
                this.completed = true;
            }
        }

        return this.completed;
    };
};

// Indicates whether the component has been destroyed or not.
let _destroyed = true;

export default {
    data() {
        return { srcOrders: null };
    },

    beforeDestroy() {
        console.log('#### Destroy open-orders page.');
        _destroyed = true;

        $.closeSocketIo();
    },

    created() {
        _destroyed = false;
        this.loadOrdersAsync();
    },

    computed: {
        openOrders: function () {
            const src = this.srcOrders;
            if (!src) return null;

            const arr = [];
            for (let i = 0; i < src.length; i++) {
                if (src[i].completed !== true) {
                    arr.push(src[i]);
                }
            }

            return arr;
        }
    },

    methods: {
        loadOrdersAsync: async function () {
            const self = this;

            try {
                if (_destroyed === false) {
                    // Read open orders
                    const json = await $.callPostApi(self, '/api/v1/boptionorder/recentopenorders');
                    if (json) {
                        if (json.errcode === 0) {
                            const arr = [];
                            for (let i = 0; i < json.data.length; i++) {
                                arr.push(new OpenOrder(json.data[i]));
                            }
                            self.srcOrders = arr;

                            if (arr.length > 0) {
                                // Start to subscribe realtime quote data
                                $.initSocketIo('/micro', (qs) => {
                                    const last_price = qs.v[1];

                                    // Update profit for existing orders.
                                    $(arr).each((index, order) => {
                                        if (order.sid === qs.id) {
                                            order.updateProfit(last_price);
                                        }
                                    });
                                });

                                const startTime = new Date();
                                const _update_progress = function () {
                                    let completed = false;

                                    // loop through each order to update process bar.
                                    let diffMS = new Date() - startTime;
                                    let numCompleted = 0;

                                    for (let i = 0; i < arr.length; i++) {
                                        if (arr[i].updateProgress(diffMS)) {
                                            // we should close the socket.io connection once no more open orders.
                                            if (++numCompleted >= arr.length) {
                                                completed = true;
                                                break;
                                            }
                                        }
                                    }

                                    if (completed) {
                                        // Close existing socket.io connection.
                                        $.closeSocketIo();
                                    } else {
                                        setTimeout(() => {
                                            _update_progress();
                                        }, 45);
                                    }
                                };
                                _update_progress();
                            }
                        }
                    }
                }
            } catch (err) {
                console.error(`ERROR: ${err}`);
                $.top_error(self.$t('general.http_error'));
            }
        }
    }
};
</script>

<style lang="less" scoped>
    .orders-page .order-item label {
        color: #9194a4;
    }
    .order-item {
        margin-bottom: 20px;
        color: #fff;
        &>label {
            color: #9194a4;
        }
    }
</style>