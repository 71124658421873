
import zh_cht_msgs from '../../locales/zh-CHT.json'
import zh_msgs from '../../locales/zh.json'
import axios from 'axios'
import VueI18n from 'vue-i18n'

function setI18nLanguage(i18n, lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)

    // Save to local storage
    localStorage.setItem('lang', lang);
    return lang
}


/*
 * ======================================================================================
 * Dynamically load locale resources
 */
const preloadedMsgs = { 'zh-CHT': zh_cht_msgs, 'zh': zh_msgs };
export function loadLanguageAsync(i18n, lang) {
    // Is the langauge updated?
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(i18n, lang))
    }

    // Is the language already loaded?
    if (preloadedMsgs[lang]) {
        return Promise.resolve(setI18nLanguage(i18n, lang))
    }

    // Load the language from server.
    return import(/* webpackChunkName: "lang-[request]" */ `../../locales/${lang}.json`).then(
        messages => {
            i18n.setLocaleMessage(lang, messages.default)
            preloadedMsgs[lang] = messages.default;
            return setI18nLanguage(i18n, lang)
        }
    )
}


/*
 * ======================================================================================
 * Init locale resources
 */
export async function initLocalizedResourcesAsync(config) {
    // Get default culture of the user.
    let defLang;
    try {
        defLang = localStorage.getItem("lang");
    } catch (err) {
        console.error(`Failed to read default lang: ${err}`);
    }

    // Is the language supported.
    if (defLang) {
        const supportedLangs = config.supportedLanguages || ['zh-CHT'];
        if (supportedLangs.indexOf(defLang) < 0)
            defLang = supportedLangs[0]; // ALWAYS USE LOWER CASE
    } else {
        // Read default language from server config.
        defLang = config.defaultLanguage || 'zh-CHT';
        if(config.supportedLanguages.includes('ko')){
            defLang ='ko';
        }
    }
    axios.defaults.headers.common['Accept-Language'] = defLang;


    const i18n = new VueI18n({
        locale: null,
        fallbackLocale: 'zh-CHT',
        messages: preloadedMsgs
    });

    // Is the default language loaded?
    if (!preloadedMsgs[defLang]) {
        console.log(`## Load strings for the default locale: ${defLang}`);
        await loadLanguageAsync(i18n, defLang);
    }

    i18n.locale = defLang;
    return i18n;
}


/*
 * ======================================================================================
 * Returns the name of the given locale.
 */
const langNames = {
  en: "English",
  es: "Español",
  zh: "简体中文",
  "zh-CHT": "繁體中文",
  ja: "日本語",
  fr: "Français",
  hi: "हिन्दी",
  id: "Bahasa Indonesia",
  ko: "한국어",
  ms: "Melayu",
  ru: "Русский",
  de: "Deutsch",
  tr: "Türkçe",
  tl: "Filipino",
  pt: "Português",
  vi: "Tiếng Việt",
  it: "Italiano",
};
export function getLocaleName(lang) {
    let val = langNames[lang];
    return val || lang;
}