<template>
    <section class="page orders-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('home.header_futures_orders') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <ul class="page-top-nav">
                        <li class="active">
                            <router-link class="nav-link active" to="/futures/openorders">{{ $t('orders.open_orders_history') }}</router-link>
                        </li>
                        <li>
                            <router-link class="nav-link" to="/futures/orders">{{ $t('orders.all_orders') }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Balance Stats -->
            <div class="row text-center" id="balance-stats">
                <!-- <div class="col">
                    <label>{{ $t('futures.orders_frozen_margin') }}</label>
                    <div>{{ isNaN(frozen_margin) ? '--' : frozen_margin.toFixed(2) }}</div>
                </div> -->
                <div class="col">
                    <label>{{ $t('futures.orders_used_margin') }}</label>
                    <div>{{ isNaN(used_margin) ? '--' : Math.round(used_margin) }}</div>
                </div>
                <div class="col">
                    <label>{{ $t('futures.orders_net_assets') }}</label>
                    <div v-if="isNaN(current_balance)">--</div>
                    <div v-else>{{ current_balance.toFixed(4) }}</div>
                </div>
                <div class="col">
                    <label>{{ $t('futures.orders_profit') }}</label>
                    <div v-if="isNaN(total_profit)">--</div>
                    <div v-else v-bind:class="{ 'color-up': total_profit >= 0, 'color-down': total_profit < 0 }">{{ total_profit.toFixed(4) }}</div>
                </div>
                <div class="col">
                    <label>{{ $t('futures.orders_risk_rate') }}</label>
                    <div v-if="isNaN(current_balance)">--</div>
                    <div v-else>
                        <b>{{ risk_rate }}</b>
                    </div>
                </div>
            </div>

            <!-- All open orders -->
            <div v-if="!orders" class="row">
                <div class="col">
                    <loading-indicator />
                </div>
            </div>
            <template v-else>
                <div v-if="orders.length === 0" class="row">
                    <div class="col">
                        <div class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="order-item" v-for="order in sortOrders" :key="order.orderId">
                            <div class="history-order">
                                <div class="row">
                                    <div class="col">
                                        <b>{{ order.symbolName }} </b>
                                        <span v-if="order.buyUp" class="color-up">{{ $t('futures.label_buy_up') }} <i class="fa fa-arrow-up"></i></span>
                                        <span v-else class="color-down">{{ $t('futures.label_buy_down') }} <i class="fa fa-arrow-down"></i></span>

                                        <span class="badge bg-danger" v-if="order.status === 1">{{ $t('orders.status_open') }}</span>
                                    </div>
                                </div>

                                <div class="row content-row">
                                    <div class="col-4 col-md-3">
                                        <label>{{ $t('futures.order_amount_history') }}</label>
                                        {{ order.numHands }} {{ $t('futures.label_unit') }}
                                    </div>
                                    <div class="col-4 col-md-3">
                                        <label>{{ $t('futures.order_create_price') }} </label>
                                        <span v-if="order.marketPrice === true">{{ $t('futures.label_market_price') }}</span>
                                        <span v-else>{{ order.createPrice }}</span>
                                    </div>

                                    <div class="col-4 col-md-3">
                                        <label>{{ $t('futures.order_margin_pct') }}</label> {{ 100 / order.leverage }}%
                                    </div>
                                    <div class="col-4 col-md-3">
                                        <label>{{ $t('futures.order_fee') }}</label> {{ order.totalFee }} <small class="text-muted">USDT</small>
                                    </div>
                                    <div class="col-4 col-md-3">
                                        <label>{{ $t('futures.order_stop_profit_point') }}</label>
                                        <span v-if="order.stopProfitPoint > 0">{{ order.stopProfitPoint }}</span>
                                        <span v-else class="text-muted">--</span>
                                    </div>
                                    <div class="col-4 col-md-3">
                                        <label>{{ $t('futures.order_stop_loss_point') }}</label>
                                        <span v-if="order.stopLossPoint > 0"> {{ order.stopLossPoint }}</span>
                                        <span v-else class="text-muted">--</span>
                                    </div>
                                    <div class="col-auto">
                                        <label>{{ $t('futures.order_create_time') }}</label> {{ new Date(order.timeCreated).formatDateTime() }}
                                    </div>
                                </div>

                                <div class="row content-row">

                                    <div class="col-4 col-md-3">
                                        <label>{{ $t('futures.order_avg_price') }}</label>
                                        <div>{{ order.matchPrice }}</div>
                                    </div>
                                    <div class="col-4 col-md-3">
                                        <label>{{ $t('futures.order_current_price') }}</label>
                                        <div v-if="isNaN(order.currentPrice)">--</div>
                                        <div v-else>{{ order.currentPrice.toFixed(order.pricePrecision) }}</div>
                                    </div>
                                    <div class="col-4 col-md-3">
                                        <label>{{ $t('futures.order_profit') }}/USDT</label>
                                        <div>
                                            <span v-if="isNaN(order.currentProfit)">--</span>
                                            <b v-else>
                                                <span :class="{ 'color-up': order.currentProfit >= 0, 'color-down': order.currentProfit < 0 }">
                                                    {{ order.currentProfit.toFixed(4) }}
                                                </span>
                                            </b>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <p class="text-right">
                                    <button class="btn btn-primary btn-sm" @click="onUpdateStopPoints(order)">
                                        {{ $t('futures.label_stop_loss') }}
                                    </button>
                                    <button class="btn btn-primary btn-sm" @click="onCloseOrder(order)">
                                        {{ $t('futures.label_close_order_title') }}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <open-order-stats ref="orderStats" @stats-updated="onStatsUpdated" @balance-updated="onBalanceUpdated" />
        <close-order-modal ref="closeOrderModal" @order-closed="onOrderClosed" />
        <update-stop-points ref="updateStopPointsModal" @order-updated="onOrderUpdated" />
    </section>
</template>

<script>
let g_risk_rate = NaN;

import CloseOrderModal from './Components/Futures_CloseOrderModal.vue';
import UpdateStopPoints from './Components/UpdateStopPointsModal.vue';
import OpenOrderStats from '../Components/FuturesOpenOrderStats.vue';

export default {
    components: { CloseOrderModal, UpdateStopPoints, OpenOrderStats },
    data() {
        return {
            // All open orders
            orders: null,

            // the currently selected order
            selected_order: {},

            // Balance stats
            current_balance: NaN,
            risk_rate: '--',
            used_margin: NaN,
            frozen_margin: NaN,
            total_profit: NaN,

            // The order to be closed.
            closingOrder: null,
        };
    },

    created() {
        this.initAsync();
    },
    computed:{
        sortOrders(){
            const list = JSON.parse(JSON.stringify(this.orders))
            return list.sort((a,b)=>{return b.timeCreated - a.timeCreated})
        }
    },

    methods: {
        initAsync: async function () {
            // Read futures config first.
            const json = await $.callPostApi(this, '/api/v1/futures/config');
            if (json && json.errcode === 0) {
                g_risk_rate = json.data.riskRate;
            }
        },

        onUpdateStopPoints: function (order) {
            this.$refs.updateStopPointsModal.openModal(order);
        },

        onCloseOrder: function (order) {
            this.closingOrder = order;
            Vue.nextTick(() => {
                this.$refs.closeOrderModal.openModal(order);
            });
        },

        onOrderClosed: function () {
            this.closingOrder = null;
            this.$refs.orderStats.reload();
        },

        onOrderUpdated: function () {
            this.$refs.orderStats.reload();
        },

        onStatsUpdated: function () {
            this.orders = this.$refs.orderStats.getOrders();
        },

        onBalanceUpdated: function () {
            const stats = this.$refs.orderStats.getStats();
            this.current_balance = stats.current_balance;
            this.used_margin = stats.used_margin;
            this.frozen_margin = stats.frozen_margin;
            this.total_profit = stats.total_profit;

            this.risk_rate = isNaN(stats.risk_rate) ? '--' : (stats.risk_rate * 100).toFixed(2) + '%';

            if (this.closingOrder) {
                // Update price/profit for the closing order.
                // Otherwise, the grantchild component won't get updated.
                this.$refs.closeOrderModal.updateOrder();
            }
        }
    }
};
</script>