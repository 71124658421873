<template>
    <div class="container">
        <loading-indicator v-if="!post" />
        <template v-else>
            <h1 class="page-title">{{ post.title }}</h1>
            <hr />

            <!-- poster -->
            <div class="text-center" v-if="post.imageUrl">
                <img :src="post.imageUrl" class="img-fluid post-image" />
            </div>

            <!-- post body -->
            <pre class="agreement" v-html="post.body"></pre>

            <!-- related -->
            <div v-if="post.related && post.related.length > 0">
                <hr />

                <div class="row mt-3 mb-3">
                    <div class="col-6 col-md-4 col-lg-3 post" v-for="(post, index) in post.related" :key="post.id" :class="{ 'd-md-none d-lg-block': index >= 3 }">
                        <router-link :to="'/post/' + post.id" class="text-start">
                            <img :src="post.imageUrl" v-if="post.imageUrl" class="img-fluid" />
                            <div class="post-title">{{ post.title }}</div>
                            <div class="post-brief" v-if="post.brief">{{ post.brief }}</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped>
h2 {
    font-size: 1.5rem;
    color: var(--text-secondary);
}

.post-image {
    max-width: 300px;
    text-align: center;
}

.post {
    padding-top: 1rem;
    text-align: center;
}

.post .img-fluid {
    width: 100%;
}

.post .post-title {
    color: var(--text-primary);
    margin: 0.5rem 0;
}

.post .post-brief {
    color: var(--text-secondary);
}
</style>

<script type="text/javascript">
export default {
    props: ['id'],

    data: function () {
        return { post: null };
    },

    mounted() {
        this.getPostAsync(this.id);
    },

    methods: {
        getPostAsync: async function (id) {
            const json = await $.callGetApi(this, '/api/v1/post?id=' + encodeURIComponent(id));
            if (json) {
                if (json.errcode === 0) {
                    this.post = json.data;

                    Vue.nextTick(() => {
                        $.scanPopup();
                    });
                } else {
                    this.$router.push('/notfound');
                }
            }
        }
    },

    watch: {
        $route() {
            this.getPostAsync(this.id);
        },

        /**
         * Update post content after the language is changed.
         */
        '$i18n.locale': function (newVal, oldVal) {
            this.getPostAsync(this.id);
        }
    }
};
</script>