<template>
    <section class="page login-page layout-login d-flex">
        <div class="d-flex flex-row login-left-right flex-fill">
            <!-- <div class="login-page-left">Left component</div> -->
            <div class="login-page-right login-container">
                <div>
                    <div class="img">
                        <img src="@/assets/images/v4.2/loginBack.png" alt="" />
                    </div>
                    <div class="login-box">
                        <div class="login container ">
                            <div class="login-inner" id="login-page">
                                <login-form-component v-if="regions" v-bind:regions="regions" v-bind:csrfToken="csrfToken" />
                                <loading-indicator v-else />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped lang="less">
.login-box{
    background: #000;
}
.img {
    flex-shrink: 0;
    padding-left: 20%;
    height: 450px;
}
.img img {
    height: 100%;
    width: 100%;
}
.login-left-right {
    margin: auto;
    width: 100%;
}


@media (max-width: 1024px) {

    .login-container {
        background: var(--bg-primary);
    }

    .login-container .img {
        display: none;
    }

    .login-container .login-box {
        width: inherit;
        background-color: #000;
    }

    .login-page .login h1 {
        color: #fff;
    }

    .login-box .container {
        max-width: 320px;
    }
    .login-box{
        background: #fff;
    }
}
</style>

<script type="text/javascript">
import LoginFormComponent from './Components/LoginForm.vue';
import { getAppContext } from 'utilities/helper';

export default {
    components: { LoginFormComponent },
    data() {
        return { regions: null, csrfToken: null };
    },

    created() {
        // Already signed in?
        const context = getAppContext();
        const profile = context.profile;
        if (profile) {
            this.$router.push('/user');
        } else {
            this.initAsync();
        }
    },

    watch: {
        '$i18n.locale': function () {
            this.reloadRegionsAsync();
        }
    },

    methods: {
        initAsync: async function () {
            const self = this;

            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');


            // Read csrf token
            const token = await $.callPostApi(self, '/api/v1/csrftoken');
            if (token && typeof token === 'string') {
                self.regions = resp.data;
                self.csrfToken = token;
            }
        },

        reloadRegionsAsync: async function () {
            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp && resp.data) {
                this.regions = resp.data;
            }
        }
    }
};
</script>