<template>
    <div v-if="posts" class="row row-block">
        <!-- posts within the category. -->
        <div v-for="post in posts" :key="post.id" class="col-12 col-sm-6 col-md-3 post-item">
            <router-link class="link" :to="`/post/${post.id}`">
                <div v-if="post.imageUrl" :style="`background-image: url(${post.imageUrl})`" class="img-wrapper">
                    <img :src="post.imageUrl" :alt="post.title" />
                </div>
                <div class="pt-2">{{ post.title }}</div>

            </router-link>
        </div>
    </div>
</template>

<style>
.row-block {
    margin: 0;
}
.post-item a {
    display: block;
    transition: all .4s;
}

.post-item img {
    width: 100%;
    height: auto;
    display: block;
    opacity: 0;
}

.post-item div.img-wrapper {
    background: transparent no-repeat center center;
    background-size: 100% 100%;
    transition: all .4s;
}

.post-item a:hover div.img-wrapper {
    background-size: 120% 120%;
}
.row-block {
    display: block;
}
.post-item {
    width: 100%;
    padding: .75rem 0;
    border-bottom: 1px solid var(--divider-secondary);
}
.post-item .link {
    display: flex;
    width: 100%;
    align-items: center;
}
.img-wrapper {
    width: 90px;
    margin-right: 20px;
}
.pt-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
}
</style>

<script lang="js">
export default {
    props: ['posts']
};
</script>