<template>
    <section class="page orders-page">
        <section class="pro_layout container">
            <h1>{{ $t('home.header_exchange_orders') }}</h1>

            <loading-indicator v-if="!exchange_orders" />
            <template v-else>
                <div class="row text-center" id="balance-stats">
                    <div class="col">
                        <label class="small">{{ $t('exchange.label_total_buy') }}</label>
                        <div>{{ total_buy_val }}</div>
                    </div>
                    <div class="col">
                        <label class="small">{{ $t('exchange.label_total_sell') }}</label>
                        <div>{{ total_sell_val }}</div>
                    </div>
                    <div class="col">
                        <label class="small">{{ $t('exchange.label_total_holding') }}</label>
                        <div>{{ total_holding_val }}</div>
                    </div>
                    <div class="col">
                        <label class="small">{{ $t('orders.label_estimated_profit') }}</label>
                        <div v-bind:class="total_profit_val >= 0 ? 'color-up' : 'color-down'">{{ total_profit_val }}</div>
                    </div>
                </div>
                <div v-if="exchange_orders.length === 0">
                    <div class="no-data-indicator m-5">{{ $t('general.no_data') }}</div>
                </div>
                <exchange-order-list v-if="exchange_orders.length > 0" :exchange_orders="exchange_orders" />
            </template>
        </section>
    </section>
</template>

<script>
import ExchangeOrderList from './Components/Orders_List.vue';
import { getSymbolManagerAsync } from 'utilities/helper';

export default {
    components: { ExchangeOrderList },

    data() {
        return {
            // All exchange orders.
            exchange_orders: null,

            // Stats
            total_buy_val: '--',
            total_sell_val: '--',
            total_holding_val: '--',
            total_profit_val: '--',

            // Exchange symbol map
            symbol_map: null
        };
    },

    mounted() {
        const self = this;
        this.buildSymbolMapAsync().then((numExchangeSymbols) => {
            if (numExchangeSymbols === 0) {
                self.exchange_orders = [];
            } else {
                // read all existing symbols
                self.getOrdersAsync();
            }
        });
    },

    methods: {
        buildSymbolMapAsync: async function () {
            const mgr = await getSymbolManagerAsync();
            const symbols = mgr.getSymbols(3); // 3: exchange symbols

            const map = {};
            $(symbols).each((index, sym) => {
                map[sym.id] = sym;
            });

            this.symbol_map = Object.freeze(map);

            // Returns the number of exchange symbols.
            return symbols.length;
        },

        getOrdersAsync: async function () {
            const self = this;

            const json = await $.callPostApi(self, '/api/v1/exchange/orders');
            if (json) {
                if (json.errcode === 0) {
                    const output = [];
                    const symbol_map = self.symbol_map;
                    $(json.data).each((index, order) => {
                        output.push(new ExchangeOrder(order, symbol_map));
                    });

                    self.exchange_orders = output;

                    // Update profit stats.
                    self.computeProfitAsync(self.exchange_orders);
                } else {
                    $.top_error(json.errmsg);
                }
            }
        },

        computeProfitAsync: async function (orders) {
            const self = this;

            let total_buy = 0;
            let total_sell = 0;
            let total_hold = 0;

            // Read latest quote for all used symbols.
            const sids = this.getUniqueSids(orders);
            const symbol_price_map = {};
            if (sids.length > 0) {
                const quotes = await $.callGetApi(self, '/api/v1/quotation/latest?symbols=' + sids.join(','));
                $(quotes).each((index, quote) => {
                    symbol_price_map[quote.id] = quote.c;
                });
            }

            const required_symbols = {};
            for (let i = 0; i < orders.length; i++) {
                const order = orders[i];

                // get integer id of the symbol
                const sym = this.symbol_map[order.sid];
                if (!sym || sym.type !== /* exchange symbol */ 3) {
                    console.error('Unknown exchange symbol ' + order.symbol);
                    return;
                }
                const price = symbol_price_map[sym.id];
                if (typeof price !== 'number' || price <= 0) {
                    console.error('Missing price data for symbol ' + order.symbol);
                    continue;
                }

                required_symbols[sym.baseSymbol] = price;
                switch (order.metadata.type) {
                    case 1:
                    case 3:
                        // buy orders
                        total_buy += order.metadata.completedAmount * order.metadata.avgPrice;
                        break;

                    case 2:
                    case 4:
                        // sell orders
                        total_sell += order.metadata.completedAmount * order.metadata.avgPrice;

                        // Need to exclude cancelled orders (2: cancelled)
                        if (order.status !== 2) {
                            total_hold += order.metadata.amount - order.metadata.completedAmount;
                        }
                        break;
                }
            }

            if (orders.length > 0) {
                // Need to read the amount of purchased coins
                const balances = await $.callPostApi(this, '/api/v1/balance/list');
                for (let i = 0; i < balances.length; i++) {
                    const item = balances[i];
                    const price = required_symbols[item.currency];
                    if (typeof price === 'number' && price > 0) {
                        total_hold += price * item.balance;
                    }
                }

                self.total_buy_val = total_buy.toFixed(2);
                self.total_sell_val = total_sell.toFixed(2);
                self.total_holding_val = total_hold.toFixed(2);
                self.total_profit_val = (total_hold + total_sell - total_buy).toFixed(2);

                // .catch((err) => {
                //     console.error(`ERROR: ${err}`);
                //     $.top_error(self.$t('general.operation_error'));
                // });
            } else {
                self.total_buy_val = '0.00';
                self.total_sell_val = '0.00';
                self.total_holding_val = '0.00';
                self.total_profit_val = '0.00';
            }
        },

        getUniqueSids: function (orders) {
            const output = [];
            const map = {};

            $(orders).each((index, order) => {
                if (map[order.sid] !== true) {
                    output.push(order.sid);
                    map[order.sid] = true;
                }
            });

            return output;
        }
    }
};

/*
 * ================================================================================================
 * the ExchangeOrder function
 */
function ExchangeOrder(order, symbol_map) {
    // is the symbols really exists
    let sym = symbol_map[order.sid];

    this.metadata = Object.freeze(order);

    this.order_id = order.orderId;
    this.time_created = new Date(order.timeCreated).formatDateTime();
    this.status = order.status;
    this.buy = order.type === 1 || order.type === 3;
    this.sid = order.sid;
    this.symbol = order.symbol;

    // determines base/quote currency for the trading pair.
    let pos = order.symbolName.indexOf('/');
    this.symbol_name = order.symbolName;
    this.base_currency = pos > 0 ? order.symbolName.substr(0, pos) : order.base_currency;
    this.quote_currency = pos > 0 ? order.symbolName.substr(pos + 1) : order.quote_currency;
    this.fee_currency = this.buy ? this.base_currency : this.quote_currency;

    this.limited_price = order.limitedPrice.toFixed(sym ? sym.pricePrecision : 8);
    this.amount = order.amount.toFixed(sym ? sym.volumePrecision : 8);

    if (order.completedAmount >= 0.000000001) {
        this.completed_amount = order.completedAmount.toFixed(sym ? sym.volumePrecision : 8);
        this.avg_price = order.avgPrice.toFixed(sym ? sym.pricePrecision : 8);

        // let feePrecision = 8;
        // if (sym) {
        //     feePrecision = this.up ? sym.pricePrecision : sym.volumePrecision;
        // }
        this.fee = order.fee.toBalanceString(); //.toFixed(feePrecision);
    } else {
        this.completed_amount = '0';
        this.avg_price = '--';
        this.fee = '0';
    }
}
</script>