<template>
    <section class="page settings-page">
        <div v-if="!profile">
            <loading-indicator />
        </div>
        <template v-else>
            <section class="top-header">
                <div class="container">
                    <div class="row align-items-end">
                        <div class="col ">
                            <div class="real-name">
                                <!-- vip status -->
                                <div>{{ profile.realName }}</div>
                                <div v-if="profile.vipLevel > 0" class="d-flex align-items-center vip-label">
                                    <img class="vipImage"  :src="vipImage" :alt="`VIP${profile.vipLevel}`">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="container setting-items">
                <div class="row">
                    <ul>
                        <li>
                            <router-link to="/user/verifyid" class="d-flex align-items-center">
                                <div class="flex-fill">
                                    {{ $t('user.label_id_verification') }}
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li v-if="sysconfig.phoneSupported">
                            <router-link to="/user/phone" class="d-flex align-items-center">
                                <div class="flex-fill">
                                    {{ $t('register.label_phone') }}
                                </div>
                                <div class="fw-normal me-2">{{ profile.phone }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li v-if="sysconfig.emailSupported">
                            <router-link to="/user/email" class="d-flex align-items-center">
                                <div class="flex-fill">
                                    {{ $t('register.label_email') }}
                                </div>
                                <div class="fw-normal me-2">{{ profile.email }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/user/reset" class="d-flex align-items-center">
                                <div class="flex-fill">
                                    {{ $t('user.label_password') }}
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li v-if="sysconfig.requiresWithdrawPassword === true">
                            <router-link to="/user/changewithdrawpwd" class="d-flex align-items-center">
                                <div class="flex-fill">
                                    {{ $t('user.label_financial_password') }}
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="jumpRoute" class="d-flex align-items-center">
                                <div class="flex-fill">
                                    {{ $t('user.label_bankaccount')}}
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/user/balance" class="d-flex align-items-center">
                                <div class="flex-fill">
                                    {{ $t('home.footer_assets') }}
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/notices" class="d-flex align-items-center">
                                <div class="flex-fill">
                                    {{ $t('notices.notice_title') }}
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/exchange/orders" class="d-flex align-items-center">
                                <div class="flex-fill" v-if="$i18n.locale === 'en'">
                                    {{ $t('home.header_exchange_orders_en') }}
                                </div>
                                <div class="flex-fill" v-else>
                                    {{ $t('home.header_exchange_orders') }}
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/futures/openorders" class="d-flex align-items-center">
                                <div class="flex-fill">
                                    {{ $t('home.header_futures_orders') }}
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li v-if="sysconfig.bOptionEnabled">
                            <router-link to="/boption/orders" class="d-flex align-items-center">
                                <div class="flex-fill">
                                    {{ $t('home.header_boption_orders') }}
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/user/signout" class="d-flex align-items-center">
                                <div class="flex-fill">
                                    {{ $t('user.label_signout') }}
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="page-part">
                <div class="container">
                    <div class="row top-icons">
                        <div class="col">
                            <div>{{ profile.realName }}</div>
                            <div>
                                {{ $t('user.balance_amount') }} <b>{{ balance }}</b> {{ currencySymbol }}
                            </div>
                        </div>
                        <div class="col text-right">
                            <router-link to="/finance/deposit" class="btn btn-primary">{{ $t('user.deposit') }}</router-link>
                            <router-link to="/finance/withdraw" class="btn btn-default">{{ $t('user.withdraw') }}</router-link>
                        </div>
                    </div>
                </div>
            </div> -->
        </template>
    </section>
</template>

<style scoped>
.real-name{
    display: flex;
}
.vip-label{
    margin-left: 10px;
}
.settings-page {
    background-image: radial-gradient(ellipse at top center, #2B7DA2 0%, #000 80%);
    background-size: 100% 50%;
    background-repeat: no-repeat;
}

.top-header {
    color: var(--top-header-text);
    background: none !important;
    overflow: hidden;
}

.top-header::v-deep .spinner-border {
    border-width: 2px;
    opacity: 0.2;
}

.top-header .svg-icon {
    width: 2rem;
    height: 2rem;
    fill: currentColor;
}

.top-header .vip-label {
    font-size: 1.25rem;
    color: var(--text-vip);
    font-weight: 400;
    border-radius: 0.25rem;
}

.icon-warning {
    height: 2rem;
    width: 2rem;
}

a svg {
    width: 1rem;
    height: 1rem;
}

ul {
    margin-top: 2rem;
    margin-bottom: 5rem;
}

ul>li {
    list-style-type: none;
}

ul>li .flex-fill{
    display: flex;
    align-items: center;
}

ul>li img{
    width: 36px;
    height: 36px;
    margin-right: 16px;
}

li>a {
    display: block;
    color: #fff;
    margin-top: 1rem;
    padding: 1rem;
    border-bottom: 1px solid rgba(216,216,216,0.38);
    font-weight: 600;
}

li>a:hover {
    color: var(--primary-hover)
}
.vipImage {
    position: relative;
    width: 100px;
    left: 6px;
    top: 3px;
}
</style>

<script>
import '@/assets/svg/icon-warning-sign.svg';
import '@/assets/svg/icon-checked.svg';
import '@/assets/svg/icon-arrow-right.svg';
import vip1 from '@/assets/images/vip/1.png'
import vip2 from '@/assets/images/vip/2.png'
import vip3 from '@/assets/images/vip/3.png'
import vip4 from '@/assets/images/vip/4.png'
import vip5 from '@/assets/images/vip/5.png'
import vip6 from '@/assets/images/vip/6.png'
import vip7 from '@/assets/images/vip/7.png'
import vip8 from '@/assets/images/vip/8.png'
import vip9 from '@/assets/images/vip/9.png'
import vip10 from '@/assets/images/vip/10.png'
import { setProfile } from 'utilities/helper';

export default {
    data() {
        return {
            profile: null,
            profile: null,
            id_status: NaN,
            jumpRoute:"/user/bankaccounts",
            vipImage:null
        };
    },

    created() {
        this.getProfileAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
    },

    methods: {
        getVipImage(level) {
            if(!level) {
                this.vipImage = null;
            }
            const vipImage = {
                vip1,vip2,vip3,vip4,vip5,vip6,vip7,vip8,vip9,vip10
            }
            if(level>10) {
                this.vipImage = vipImage.vip10
            } else {
                this.vipImage = vipImage['vip' + level]
            }
        },
        async getBindBankStatus(){
            if(this.profile === null) return
            const json = await $.callGetApi(this, '/api/v1/bankaccount/list?id=bank');
            if (json.errcode === 0 && json.data.length === 0) {
                this.jumpRoute = "/user/bankaccount"; 
            } else {
                this.jumpRoute=this.sysconfig.region === 'de'?"/user/bankaccounts":`/user/bankaccounts`;       
            }
        },
        getProfileAsync: async function () {
            const self = this;
            const json = await $.callPostApi(self, '/api/v1/account/profile');

            // Could be undefined if authentication is required.
            if (json && json.errcode === 0 && json.data) {
                setProfile(json.data);
                let profile = Object.freeze(json.data);

                // Read ID verification status
                const resp = await $.callGetApi(self, '/api/v1/identityverification');
                if (resp) {
                    if (typeof resp.data !== 'number' || resp.data < 0) throw new Error('Invalid id-verification status response.');
                    self.id_status = resp.data;
                }

                self.profile = profile;
                self.getVipImage(profile.vipLevel)
                self.getBindBankStatus();
            }
        }
    }
};
</script>