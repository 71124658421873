<template>
    <div class="order-item">
        <div class="history-order">
            <div class="row">
                <div class="col">
                    <label>{{ $t('orders.label_symbol') }}</label> {{ order.symbolName }} {{ order.duration }}S
                    <span class="badge bg-danger" v-if="order.status === 1">{{ $t('orders.status_open') }}</span>
                    <span class="badge bg-danger" v-if="order.status === 4 && order.errorCode !== 0">{{ $t('orders.status_invalid') }}</span>
                    <span class="badge bg-danger" v-if="order.status === 5">{{ $t('orders.status_failed') }}</span>
                </div>
                <div class="col">
                    <label>{{ $t('orders.label_direction') }}</label>
                    <span v-if="order.buyUp" class="color-up">{{ $t('orders.label_direction_up') }} <i class="fa fa-arrow-up"></i></span>
                    <span v-else class="color-down">{{ $t('orders.label_direction_down') }} <i class="fa fa-arrow-down"></i></span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>{{ $t('orders.label_create_price') }}</label> {{ order.createPrice.toFixed(order.pricePrecision) }}
                </div>
                <div class="col">
                    <label>{{ $t('orders.label_currency') }}</label>
                    {{ order.currency === 'USDT' ? $t('orders.label_currency_cash') : $t('orders.label_currency_coupon') }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>{{ $t('orders.label_amount') }}</label> {{ order.amount }}{{ order.currency }}
                </div>
                <div class="col">
                    <label>{{ $t('orders.label_fee') }}</label> {{ order.fee.toFixed(2) }}
                </div>
            </div>
            <div class="row" v-if="order.status === 4 && order.errorCode === 0">
                <div class="col">
                    <label>{{ $t('orders.label_close_price') }}</label> {{ order.closePrice.toFixed(order.pricePrecision) }}
                </div>
                <div class="col">
                    <label>{{ $t('orders.label_profit') }}</label> {{ order.profit.toFixed(2) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['order']
};
</script>
