<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="home_quotes">
                    <loading-indicator v-if="!symbols" />
                    <template v-else>
                        <!-- <div class="row">
                            <div class="col">Futuers symbols</div>
                            <div class="col-auto">
                                <router-link to="/quotes">
                                    {{ $t('home.label_more') }}
                                    <svg width="1em" height="1em" viewBox="0 0 1024 1024">
                                        <path d="M128 469.333333h604.586667l-152.746667-153.173333L640 256l256 256-256 256-60.16-60.16L732.586667 554.666667H128z"></path>
                                    </svg>
                                </router-link>
                            </div>
                        </div>
                        <hr /> -->
                        <symbol-list ref="sym_list" :symbols="symbols" :typeFilter="3" @symbol-selected="gotoTradePage" />
                    </template>

                    <!-- <div class="text-center p-5">
                        <router-link to="/quotes">
                            {{ $t('home.label_more') }}
                            <svg width="1em" height="1em" viewBox="0 0 1024 1024">
                                <path d="M128 469.333333h604.586667l-152.746667-153.173333L640 256l256 256-256 256-60.16-60.16L732.586667 554.666667H128z"></path>
                            </svg>
                        </router-link>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* .home_quotes {
    background: #fff;
} */
hr {
    background-color: var(--divider-secondary);
    opacity: 0.1;
}
</style>

<script>
import SymbolList from './SymbolList.vue';

export default {
    components: { SymbolList },

    props: ['symbols'],

    methods: {
        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        getVisibleSids: function () {
            return this.symbols ? this.$refs.sym_list.getVisibleSids() : [];
        }
    }
};
</script>