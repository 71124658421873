<template>
    <section class="home-section">
        <!-- Commitment section -->
        <div class="commitment-content">
            <div class="container">
                <div class="otc-info-box">
                    <p class="title">{{ $t('intro_my.md_3') }}</p>
                    <p class="title-desp">{{ $t('intro_my.md_3_desp') }}</p>
                    <span class="go-otc" onclick="toggle_udesk()">
                        <span>{{ $t('general.customer_service_long') }}</span>
                        <svg class="svg-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#home-arrow-grey" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <div class="quote-bg-box">
            <p class="bg-title">{{ $t('v42.security_title') }}</p>
            <p class="bg-desp">{{ $t('v42.security_desp') }}</p>
            <img src="@/assets/svg/biss/intro-quote-bg.png" alt="">
        </div>
        <!-- Good tips -->
        <div class="good-tips-box">
            <div class="box-img"><img src="@/assets/svg/biss/big-word.png"></div>
            <div class="good-list-box">
                <div class="tips-list">
                    <p class="title">{{ $t('home_biss.commitment_title',{ platform: sysconfig.name }) }}</p>
                    <div class="tips-item">
                        <div class="svg-box">
                            <svg class="svg-icon" viewBox="0 0 256 256">
                                <use xlink:href="/dist/svg/icons.svg#intro-good-1" />
                            </svg>
                        </div>
                        <div class="text-box">
                            <p class="text-title">{{ $t('home_biss.commitment_1') }}</p>
                            <p class="text-desp">{{ $t('home_biss.commitment_1_desp', { platform: sysconfig.name }) }}</p>
                        </div>
                    </div>
                    <div class="tips-item">
                        <div class="svg-box">
                            <svg class="svg-icon" viewBox="0 0 256 256">
                                <use xlink:href="/dist/svg/icons.svg#intro-good-2" />
                            </svg>
                        </div>
                        <div class="text-box">
                            <p class="text-title">{{ $t('home_biss.commitment_2') }}</p>
                            <p class="text-desp">{{ $t('home_biss.commitment_2_desp', { platform: sysconfig.name }) }}</p>
                        </div>
                    </div>
                    <div class="tips-item">
                        <div class="svg-box">
                            <svg class="svg-icon" viewBox="0 0 256 256">
                                <use xlink:href="/dist/svg/icons.svg#intro-good-3" />
                            </svg>
                        </div>
                        <div class="text-box">
                            <p class="text-title">{{ $t('home_biss.commitment_3') }}</p>
                            <p class="text-desp">{{ $t('home_biss.commitment_3_desp', { platform: sysconfig.name }) }}</p>
                        </div>
                    </div>
                    <div class="tips-item">
                        <div class="svg-box">
                            <svg class="svg-icon" viewBox="0 0 256 256">
                                <use xlink:href="/dist/svg/icons.svg#intro-good-4" />
                            </svg>
                        </div>
                        <div class="text-box">
                            <p class="text-title">{{ $t('home_biss.commitment_4') }}</p>
                            <p class="text-desp">{{ $t('home_biss.commitment_4_desp', { platform: sysconfig.name }) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Helps section -->
        <div class="help-section-content">
            <div class="container">
                <h2>{{ $t('home_biss.help_title') }}</h2>
                <div class="help-section-box">
                    <div class="help-section-item item1">
                        <span class="help-section-title">{{ $t('home_biss.help_1') }}</span>
                        <span class="help-section-desp">{{ $t('home_biss.help_1_desp', { platform: sysconfig.name }) }}</span>
                    </div>
                    <div class="help-section-item item2">
                        <span class="help-section-title">{{ $t('home_biss.help_2') }}</span>
                        <span class="help-section-desp">{{ $t('home_biss.help_2_desp') }}</span>
                    </div>
                    <div class="help-section-item item3">
                        <span class="help-section-title">{{ $t('home_biss.help_3') }}</span>
                        <span class="help-section-desp">{{ $t('home_biss.help_3_desp', { platform: sysconfig.name }) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="notice-box">
            <div class="container">
                {{ $t('home_biss.commitment_6_desp', { platform: sysconfig.name }) }}
            </div>
        </div>
    </section>
</template>


<style scoped>
/**
 * ============================================================================
 * commitments
 */

 .commitment-content{
    background-size: cover;
    height: 1142px;
    display: flex;
    align-items: center;
    margin-top: 80px;
 }
 .home-section h2 {
    text-align: center;
    word-break: keep-all;
 }

 .otc-info-box,.otc-info-box svg{
    color: #fff;
 }
 .title{
    font-weight: 600;
    font-size: 28px;
    line-height: 39px;
    text-align: center;
 }
 .title-desp{
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin: 8px 0 24px;
 }
.go-otc{
    background: #fff;
    padding: 12px 24px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
}
.go-otc span{
    margin-right: 8px;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
}
.quote-bg-box{
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -150px;
}
.quote-bg-box p{
    font-weight: 600;
    font-size: 36px;
    color: #FFFFFF;
    line-height: 50px;
    margin-bottom: 124px;
}
.quote-bg-box img{
    width: 914px;
    height: 448px;
}
.good-tips-box{
    display: flex;
}
.good-list-box{
    display: flex;
    justify-content: flex-start;
    width: 50%;
    padding-top: 326px;
}
.box-img{
    width: 50%;
    display: flex;
    justify-content: right;
}
.tips-list{
    width: 80%;
    color: #fff;
}
.tips-item{
    display: flex;
}
.tips-item .svg-box{
    margin-right: 20px;
    width: 64px;
    height: 64px;
}
.svg-box svg{
    width: 64px;
    height: 64px;
}
.tips-list .title{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 49px;
    text-align: left;
}
.tips-list .text-title{
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    margin: 14px 14px;
}
.tips-list .text-desp{
    font-weight: 400;
    font-size: 16px;
    color: #969699;
    line-height: 24px;
}
.help-section-box{
    display: flex;
    justify-content: space-between;
}
.item1{
    background: url(@/assets/images/v4.2/intro-section-1.png) no-repeat center;
}
.item2{
    background: url(@/assets/images/v4.2/intro-section-2.png) no-repeat center;
}
.item3{
    background: url(@/assets/images/v4.2/intro-section-3.png) no-repeat center;
}
.help-section-item{
    width: 32%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 324px 44px 0;
    background-size: cover;
    height: 521px;
    margin-top: 36px;
}
.help-section-desp{
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 12px;
}
.help-section-title{
    font-weight: 600;
    font-size: 28px;
    color: #FFFFFF;
    line-height: 39px;
}
.notice-box{
    height: 177px;
    background: #2E2E2E;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 32px;
    color: #999999;
    line-height: 45px;
    margin: 140px 0;
}
.quote-bg-box .bg-title {
    margin-bottom: 1.5rem;
}
.quote-bg-box .bg-desp {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: normal;
}
@media (max-width: 1024px) {
    .box-img{
        display: none;
    }
    .good-list-box{
        width: 100%;
    }
    .commitment-content{
        height: 466px;
        background-size: cover;
        margin-top: 40px;
    }
    .otc-info-box{
        padding: 0 10px;
    }
    .commitment-content .title{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }
    .commitment-content .title-desp{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
    .go-otc{
        padding: 5px 11px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }
    .go-otc span{
        font-size: 14px;
        line-height: 18px;
    }
    .quote-bg-box img{
        width: 100%;
        height: auto;
    }
    .good-tips-box{
        background: none;
    }
    .quote-bg-box{
        margin-top: 0;
    }
    .quote-bg-box p{
        margin-bottom: 57px;
    }
    .tips-list{
        width: auto;
        padding-left: 30px;
        padding-right: 30px;
    }
    .tips-list .title{
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 32px;
    }
    .tips-item{
        border-bottom: 1px solid #D8D8D8;
        margin-bottom: 17px;
    }
    .tips-list .text-title{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 6px 0;
    }
    .tips-list .text-desp{
        font-weight: 400;
        font-size: 12px;
        color: #969699;
        line-height: 16px;
    }
    .home-section h2,.home-section .bg-title{
        text-align: center;
        font-size: 16px;
        word-break: keep-all;
    }
    .home-section .bg-title {
        margin-bottom: 0;
    }
    .home-section .bg-desp {
        font-size: 12px;
    }
    .home-section .justify-content-center {
        font-size: 16px;
    }
    .help-section-box{
        flex-direction: column;
    }
    .help-section-item{
        width: auto;
        margin: 0 30px 10px;
        padding: 0 20px 40px;
        height: 88vw;
        background-position-y: top;
    }
    .help-section-title{
        font-size: 16px;
        line-height: 22px;
        word-break: keep-all;
    }
    .help-section-desp{
        font-size: 12px;
    }
    .notice-box{
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        padding: 8px;
        margin: 0;
    }
}
</style>

<script type="text/javascript">
import '@/assets/svg/biss/home-arrow-grey.svg';
import '@/assets/svg/biss/intro-good-1.svg';
import '@/assets/svg/biss/intro-good-2.svg';
import '@/assets/svg/biss/intro-good-3.svg';
import '@/assets/svg/biss/intro-good-4.svg';
import { getAppContext } from 'utilities/helper';

export default {
     data() {
        return {
            context: null
        };
    },
    created() {
        this.context = getAppContext();
    },
    methods:{
        goOtc(){
            if(this.context.profile){
                this.$router.push('/user/login');
            } else {
                this.$router.push('/futures')
            }
        }
    }
}
</script>
